<template>
    <div style="width:100%">
        <table v-if="cerdata !=null"  border="0px solid black" cellspacing="0" style="width:100%">
            <tr>
                <td align="center" valign="bottom">
                    <div style="margin:0 auto">
                        <h2>电动车自行车产品合格证</h2>
                        <h3>第一部分车辆总体信息</h3>
                    </div>
                    <table  border="0px solid black" cellspacing="0" style="font-size:11px;width:90%" >
                        <tr >
                            <td width="50%" style="padding-top:3px;padding-bottom:3px">0.0 整车编码：</td>
                            <td width="50%">{{cerdata.vehicleNo}}</td>
                        </tr>
                        <tr >
                            <td style="padding-top:4px;padding-bottom:4px">0.1 车辆制造商：</td>
                            <td >{{cerdata.manufacturer}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.2 生产企业名称：</td>
                            <td >{{cerdata.producer}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.3 生产企业地址</td>
                            <td >{{cerdata.produceraddr}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.4 车辆中文商标：</td>
                            <td >{{cerdata.cnbrand==null?'':cerdata.cnbrand}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.5 车辆英文商标：</td>
                            <td>{{cerdata.enbrand==null?'':cerdata.enbrand}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.6 产品型号：</td>
                            <td>{{cerdata.modelno}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.7 驱动方式：</td>
                            <td>{{cerdata.drivetype}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.8 长*宽*高(mm)：</td>
                            <td>{{cerdata.bodysize}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.9 前后轮中心距(mm)：</td>
                            <td>{{cerdata.wheeltrack}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.10 装配完整的电动自行车的整车质量(kg)：</td>
                            <td>{{cerdata.totalweight}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.11 最高设计车速(km/h)：</td>
                            <td>{{cerdata.maxspeed}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.12 铭牌固定位置：</td>
                            <td>{{cerdata.cardposition}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.13 车架上车辆编码的位置：</td>
                            <td>{{cerdata.codeposition}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.14 电动机编码：</td>
                            <td>{{cerdata.motorNo}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.15 续行里程(km)：</td>
                            <td>{{cerdata.driverange}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.16 百公里电耗(kw.h/100km)：</td>
                            <td>{{cerdata.powerconsumption}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.17.1 CCC证书编号：</td>
                            <td>{{cerdata.cccno}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.17.2 CCC证书版本号：</td>
                            <td>{{cerdata.cccver}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.17.3 CCC证书发证日期：</td>
                            <td>{{cerdata.cccdate==null?'':cerdata.cccdate.replace(/\s[\x00-\xff]*/g,'')}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.18 车辆生产日期：</td>
                            <td>{{cerdata.madeDate==null?'':cerdata.madeDate.replace(/\s[\x00-\xff]*/g,'')}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.19 产品名称：</td>
                            <td>{{cerdata.productname}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top:3px;padding-bottom:3px">0.20 车身颜色：</td>
                            <td>{{cerdata.vehicleColor==null?'':cerdata.vehicleColor}}</td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td align="center" valign="bottom" >
                <table  border="1px solid black" cellspacing="0" style="font-size:11px;width:90%">
                    <tr>
                        <td colspan="4" align="left" style="padding-top:3px;padding-bottom:3px">产品合格证编号：{{cerdata.certificateno}}</td>
                    </tr>
                    <tr>
                        <td colspan="4" align="center" style="padding-top:3px;padding-bottom:3px"><h2>第二部分产品合格证参数</h2></td>
                    </tr>
                    <tr>
                        <td colspan="4" align="center" v-if="cerdata.area=='全国'">
                            <img  v-if="cerdata.picchinaaddr !=null" :src="'/api/files/get?imgpath='+cerdata.picchinaaddr"/>
                            <img  v-else style="border:0" />
                        </td>
                        <td colspan="4" align="center" v-if="cerdata.area=='上海'">
                            <img  v-if="cerdata.picshanghaiaddr !=null" :src="'/api/files/get?imgpath='+cerdata.picshanghaiaddr"/>
                            <img  v-else style="border:0"/>
                        </td>
                    </tr>
                    <tr>
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">1.1 电动机生产企业</td>
                        <td width="31%">{{cerdata.motorproducer}}</td>
                        <td width="19%">1.2 电动机型号</td>
                        <td width="31%">{{cerdata.motormodel}}</td>
                    </tr>
                    <tr>
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">1.3 电动机型式</td>
                        <td width="31%">{{cerdata.motortype}}</td>
                        <td width="19%">1.4 额定转速(r/min)</td>
                        <td width="31%">{{cerdata.rotationspeed}}</td>
                    </tr>       
                    <tr>
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">1.5 额定连续输出功率(W)</td>
                        <td width="31%">{{cerdata.outputpower}}</td>
                        <td width="19%">1.6 额定电压(V)</td>
                        <td width="31%">{{cerdata.voltage}}</td>
                    </tr>    
                    <tr>
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">2.1 控制器生产企业</td>
                        <td width="31%">{{cerdata.controllerproducer}}</td>
                        <td width="19%">2.2 控制器型号</td>
                        <td width="31%">{{cerdata.controllerno}}</td>
                    </tr>    
                    <tr>
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">2.3 欠压保护值(V)</td>
                        <td width="31%">{{cerdata.undervoltage}}</td>
                        <td width="19%">2.4 过流保护值(A)</td>
                        <td width="31%">{{cerdata.overcurrent}}</td>
                    </tr>     
                    <tr>
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">3.1 蓄电池类型</td>
                        <td width="31%">{{cerdata.celltype}}</td>
                        <td width="19%">3.2 蓄电池生产企业</td>
                        <td width="31%">{{cerdata.cellproducer}}</td>
                    </tr>     
                    <tr>
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">3.3 蓄电池容量(Ah)</td>
                        <td width="31%">{{cerdata.cellvolume}}</td>
                        <td width="19%">3.4 蓄电池型号</td>
                        <td width="31%">{{cerdata.cellno}}</td>
                    </tr>      
                    <tr>
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">4.1 前轮轮胎规格</td>
                        <td width="31%">{{cerdata.frontwheel}}</td>
                        <td width="19%">4.2 后轮轮胎规格</td>
                        <td width="31%">{{cerdata.backwheel}}</td>
                    </tr>    
                    <tr v-if="cerdata.area=='上海'" >
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">5.1 充电器商标</td>
                        <td width="31%">{{cerdata.chargerbrand}}</td>
                        <td width="50%" colspan="2" rowspan="3">
                            <div id="qrcode2" style="width:80px;height:80px"></div>
                        </td>
                    </tr>  
                    <tr v-if="cerdata.area=='上海'" >
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">5.2 充电器型号规格</td>
                        <td width="31%">{{cerdata.chargerspecs}}</td>
                    </tr>  
                    <tr v-if="cerdata.area=='上海'" height="80px">
                        <td width="19%" style="padding-top:3px;padding-bottom:3px">5 备注</td>
                        <td width="31%">{{cerdata.note}}</td>
                    </tr>                       
                    <tr v-if="cerdata.standardtype == 1">
                        <td colspan="4">本产品经过检验，符合GB 17761-2018《电动自行车安全技术规范》的要求，特此证明。</td>
                    </tr>                                  
                </table>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    // 对Date的扩展，将 Date 转化为指定格式的String
    // 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
    // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
    // 例子： 
    // (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
    // (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
    Date.prototype.Format = function (fmt) {
        var o = {
            "M+": this.getMonth() + 1, //月份 
            "d+": this.getDate(), //日 
            "H+": this.getHours(), //小时 
            "m+": this.getMinutes(), //分 
            "s+": this.getSeconds(), //秒 
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
            "S": this.getMilliseconds() //毫秒 
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    }

    import QRCode from 'qrcodejs2'
    export default {
        name:'CertificateShow',
        props:['cerprintdata'],
        data(){
            return {
                cerdata:null,
                ccclist:this._self.$root.$store.state.CccList,
            }
        },
        methods:{
            qrcode() {
                return
                var prefix=''
                var suffix=''
                var cccindex = this.cerdata.cccindex
                console.log(this.cerdata)
                console.log(cccindex)
                console.log(this.ccclist)
                for(var i=0;i<this.ccclist.length;i++){
                    if(this.ccclist[i].id == cccindex){
                        prefix = this.ccclist[i].prefix
                        suffix = this.ccclist[i].suffix      
                        break  
                    }
                }
                console.log(prefix)
                let qrcodedata = new QRCode('qrcode', {
                    width: 80,  
                    height: 80,
                    text:prefix+this.cerdata.vehicleNo+suffix, // 二维码地址
                    colorDark : "#000",
                    colorLight : "#fff",
                    //降低级别，否则可能识别不出来
                    correctLevel:QRCode.CorrectLevel.M
                })
            },
            qrcode2() {
                var url= window.location.protocol+"//"+window.location.host+"/#/showcertificate/"+this.cerdata.vehicleNo      
                //document.getElementById('qrcode2').innerHTML = ''
                let qrcodedata2 = new QRCode('qrcode2', {
                    width: 80,  
                    height: 80,
                    text: url, // 二维码地址
                    colorDark : "#000",
                    colorLight : "#fff",
                    correctLevel:QRCode.CorrectLevel.M
                })
            },
            refreshCcclist(){
                this.$axios({
                        method:'post',
                        url:"/api/ccc/query",
                        data:null,
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                        transformRequest: function(obj) {
                            var str = [];
                            for(var p in obj){
                                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                            }
                            return str.join("&");
                        }
                    }).then(function(res){
                        if(res.data.resultCode ==0){
                            var rspdata = res.data.data
                            this.ccclist =rspdata
                            this.$store.commit('changeCccList',this.ccclist)
                        }
                        else{
                            this.$Message.error({content:res.data.data.msg, duration:3})
                        }
                    }.bind(this));   
            },
        },
        watch:{
            cerprintdata (n){
                var qrelement=document.getElementById('qrcode')
                var qr2element=document.getElementById('qrcode2') 
                if(qrelement !=null){
                    qrelement.innerHTML =''
                }
                if(qr2element !=null){
                    qr2element.innerHTML =''
                }
                if(n !=null){
                    this.cerdata = this.extend({},n.cer,n.vehicle)
                    /*setTimeout(() =>{
                        this.qrcode()
                        this.qrcode2()
                    },"30");*/
                }
            },
            cerdata (n){
                if(n!=null){
                    this.$nextTick(function () {
                        this.qrcode2()        
                    // Code that will run only after the
                    // entire view has been rendered
                    })
                }
            }
        },
        mounted(){
            var vin =this.$route.params['vin']
            var newurl = window.location.protocol+"//"+window.location.host+"/public/#/certificate/showdetail_shanghai/"+vin
            window.location.href =newurl
        }
        /*
        mounted(){
            this.cerdata =this.cerprintdata
            if(this.cerprintdata !=null){
                if(this.cerprintdata.cer.picshanghaiaddr !=null && this.cerprintdata.cer.picshanghaiaddr !='null'){
                    this.cerprintdata.cer.picshanghaiaddr = encodeURIComponent(this.cerprintdata.cer.picshanghaiaddr)        
                }
                if(this.cerprintdata.cer.picchinaaddr !=null && this.cerprintdata.cer.picchinaaddr !='null'){
                    this.cerprintdata.cer.picchinaaddr = encodeURIComponent(this.cerprintdata.cer.picchinaaddr)        
                }
                this.cerdata = this.extend({},this.cerprintdata.cer,this.cerprintdata.vehicle)
            }
            console.log(this.cerdata)
            var vin =this.$route.params['vin']
            console.log(vin)
            if(this.ccclist ==null){
                this.refreshCcclist()
            }

            if(vin !=null){
                this.$axios({
                    method:'post',
                    url:"/api/vehicle/querycerdetail",
                    data:{vehicleno:vin},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        //this.afterAddVehicle()
                        console.log(res.data.data)
                        if(res.data.data.picshanghaiaddr=='null' ||res.data.data.picshanghaiaddr==''){
                            res.data.data.picshanghaiaddr=null
                        }
                        else{
                            res.data.data.picshanghaiaddr = encodeURIComponent(res.data.data.picshanghaiaddr)
                            console.log(res.data.data.picshanghaiaddr)
                        }
                        if(res.data.data.picchinaaddr=='null' ||res.data.data.picchinaaddr==''){
                            res.data.data.picchinaaddr=null
                        }
                        else{
                            res.data.data.picchinaaddr = encodeURIComponent(res.data.data.picchinaaddr)
                            console.log(res.data.data.picchinaaddr)
                        }
                        this.cerdata = res.data.data
                        
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));    
            }
        }*/
    }
</script>

<style type="text/css">
.printtd{
    padding-top:5px;
    padding-bottom:5px;
    height:40px;
}
</style>